/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	aside#layout-menu {
		display: none;
	}
	button.hamburgerMenuBtn {
		display: block;
	}
	.profileBtn span {
		display: none;
	}
	.bannerSection .cardBox {
		overflow: hidden;
		min-height: auto;
	}
	section.cardBoxWrapper .cardBox {
		margin-bottom: 15px;
	}
	button.hamburgerMenuCloseBtn {
		display: flex;
		height: 0px;
		background: transparent;
		border: none;
		width: 105%;
		justify-content: flex-end;
	}
	.hamburgerMenuCloseBtn > img {
		height: 25px;
	}
	.hamburgerMenuCloseBtn > img:hover {
		box-shadow: -5px 10px 10px #888888;
	}

	.productDetailsBody {
		display: block !important;
	}

	.productDetailsBody .btn-warning {
		width: 100%;
		margin-top: 10px;
	}

	.productDetailsBodyBottom {
		display: block !important;
	}

	.productDetailsBodyBottom .btn.btn-link {
		width: 100%;
	}

	.emp-profile li.nav-item {
		border-bottom: 1px solid #ccc;
	}

	.emp-profile .nav {
		display: block;
		background: #efefef;
	}

	.profile-head .nav-tabs .nav-link {
		width: 100%;
		text-align: left;
	}
}

@media only screen and (max-width: 480px) {
	.RebuttonArea {
		display: block !important;
	}
	.RebuttonArea .col-3 {
		flex: 0 0 auto;
		width: 100%;
	}
	.RebuttonArea .col-9 {
		flex: 0 0 auto;
		width: 100%;
	}
	.RebuttonArea .btn {
		display: block;
		width: 100%;
		margin-top: 10px;
	}
	.search-box-width-fix {
		min-width: 100%;
	}

	.detailsDataSec .card-header {
		display: block;
		font-size: 14px;
		text-align: left;
	}
	.btn {
		font-size: 1rem !important;
	}

	.pageformSection .card {
		padding: 20px;
	}

	.emp-profile .profile-img {
		margin-bottom: 15px;
	}

	.modal-footer {
		justify-content: center;
	}

	.mySidebar::after{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: hsl(220, 67%, 35%, 0.9) !important;
		left: 240px;
		top: 0px;
	}
	.productDetailsBody .btn {
		width: 100%;
	}
	.productDetailsBody {
		line-height: 30px;
	}
	.plus-minus-case .btn, .plus-minus-box .btn {
		height: 30px;
		margin-bottom: 1px !important;
		line-height: 1;
	}
	.plus-minus-case, .plus-minus-box {
		float: right;

	}
	.plus-minus-box {
		margin-top: -25px;
	}
	.productDetailsBodyBottom {
		margin-top: 10px !important;
	}
}

@media only screen and (max-width: 767.98px) {
	.mob-order {
		display: flex;
		flex-direction: column;
	}
	.order-m-01{
		order: 0;
	}

	.order-m-02{
		order: 1;
	}
}

@media only screen and (max-width: 767px) {
	.headRight {
		text-align: left;
	}
	.headRight p,
	.topSubHead p {
		margin-bottom: 0px;
	}

	.tabfooterRight {
		text-align: left;
	}

	.asideMenu {
		width: 100%;
	}

	button.hamburgerMenuCloseBtn {
		position: fixed;
		right: 30px;
		width: auto;
	}
	.bannerSection .carousel-item {
		height: auto;
	}


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	aside#layout-menu {
		display: none;
	}
	button.hamburgerMenuBtn {
		display: block;
	}
	section.cardBoxWrapper .cardBox {
		margin-bottom: 15px;
	}
	button.hamburgerMenuCloseBtn {
		display: flex;
		height: 0px;
		background: transparent;
		border: none;
		width: 105%;
		justify-content: flex-end;
	}
	.hamburgerMenuCloseBtn > img {
		height: 25px;
	}
	.hamburgerMenuCloseBtn > img:hover {
		box-shadow: -5px 10px 10px #888888;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	section.cardBoxWrapper .cardBox {
		margin-bottom: 0px;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	aside#layout-menu {
		display: flex;
		height: 100%;
		position: fixed;
	}
	button.hamburgerMenuBtn {
		display: none;
	}
	button.hamburgerMenuCloseBtn {
		display: none;
	}

	.layout-content-navbar .layout-page {
		margin-left: 260px;
	}

	/* .verticleMenu {
        height: 80%;
        overflow: hidden; */
	/* overflow-y: scroll; */
	/* } */
}

/* small devices (small laptops, 1200px and down) */
@media only screen and (max-width: 1200px) {
	.asideMenu {
		display: flex;
		height: 100vh;
	}
}

@media only screen and (max-width: 480.98px) {
	.cartFooter .vr {
		display: none;
	}
	.cartFooter {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: flex-start;
		align-items: flex-start;
	}
	.detailsDataSec .card-header  img {
		float: right;
		vertical-align: middle;
	}

	.card-header .text-end img {
		float: right;
	}
}