@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');

/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
.whitePapper {
	border-radius: 0.25rem !important;
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.whitePapper2 {
	border-radius: 0.25rem !important;
	--bs-bg-opacity: 1;
	/* background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; */
	margin-bottom: 1.5rem !important;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.inLineSpinner {
	width: 1.5rem;
	height: 1.5rem;
	margin-left: 0.5rem;
}
/* Track */
::-webkit-scrollbar-track {
	background: #bdbdbd;
	border-radius: 5px;
}
.panelBody {
	height: 0px;
	overflow: hidden;
	transition: height 0.5s ease;
}

/* Handle #607D8B */
::-webkit-scrollbar-thumb {
	background: #5980cf;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #1e4696;
}

h3 {
	font-size: 18px;
	font-weight: 500;
	color: #000;
}
h3 span {
	font-size: 14px;
	font-weight: 500;
	color: #727272;
}

.bg-success {
	background-color: #53ba4a !important;
}
.bg-primary {
	background-color: #2880c5 !important;
}
.bg-danger {
	background-color: #ff4444 !important;
}
.fs-6 {
	font-size: 12px !important;
}
.bg-primary {
	background-color: #1e4696 !important;
}
body {
	background-color: #e5e5e5;
	font-family: 'Poppins', sans-serif;
	color: #585858;
}

button.hamburgerMenuBtn {
	background-color: transparent;
	border: none;
	outline: none;
}

.layout-wrapper,
.layout-container {
	width: 100%;
	display: flex;
	flex: 1 1 auto;
	align-items: stretch;
}

.layout-content-navbar .layout-page {
	flex-direction: column;
	width: 0;
	min-width: 0;
	max-width: 100%;
}

.navbar {
	position: sticky;
	top: 0px;
	z-index: 999;
}

.layout-container {
	min-height: 100vh;
}

.content-wrapper {
	padding: 20px 30px;
}
.asideMenu {
	box-shadow: 0 0.125rem 0.375rem 0 rgba(161, 172, 184, 0.12);
	background-color: #fff !important;
	color: #5a5a5a;
	width: 260px;
	flex-direction: column;
	display: flex;
	min-height: 1px;
	padding: 25px 10px;

	overflow-y: auto;
	overflow-x: hidden;
	/* justify-content: space-between; */
}

.asideMenu .form-select {
	border-radius: 0;
	border: none;
	border-bottom: 1px solid #cacaca;
	margin-bottom: 15px;
	font-size: 13px;
	color: #5f5e5e;
	font-weight: 500;
}

.verticleMenu a {
	font-size: 14px;
	color: #5a5a5a;
	margin-bottom: 10px;
	display: flex;
}
.verticleMenu a:hover {
	color: #f57a11;
}
.verticleMenu a.nav-link.active {
	color: #f57a11;
}

.verticleMenu span {
	margin-right: 20px;
}
.verticleMenu span img {
	width: 28px;
}

.asideMenu.nav-link:focus,
.asideMenu.nav-link:hover {
	color: #0a58ca;
}

.logo-brand {
	width: 100%;
	height: auto;
	display: block;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 30px;
}

.navBottomBtn {
	text-align: center;
}

.logOutBtn {
	width: 161px;
	height: 46px;
	border-radius: 25px;
	background-color: #1e4696;
	border: none;
	outline: none;
	color: #fff;
}
.logOutBtn img {
	margin-right: 8px;
}

.layout-page {
	display: flex;
	flex: 1 1 auto;
	align-items: stretch;
	padding: 0;
}

.bannerSection img {
	width: 100%;
}

.bannerSection .carousel-item {
	height: 364px;
}

.menu-inner {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	height: 100%;
}

.asideMenu .menu-inner {
	flex-direction: column;
	flex: 1 1 auto;
}

.hamburgerMenuCloseBtn {
	display: none;
}

.bannerSection {
	margin-bottom: 28px;
}
.bannerSection .cardBox {
	overflow: hidden;
}

.cardBoxWrapper {
	width: 100%;
	height: auto;
	margin-bottom: 28px;
}

.card-body {
	font-size: 14px;
	padding: 8px 15px;
}

.cardBox {
	width: 100%;
	height: auto;
	min-height: 125px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	/* border-radius: 8px; */
	border-radius: 0px;
	box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
	overflow: hidden;
}

.tableSection {
	width: 100%;
	height: auto;
	margin-top: 20px;
}
/* .tableSection .cardBox{
	max-height: 600px;
	overflow: hidden;
	overflow-y: scroll;
} */

.tableSection .cardBox {
	min-height: 600px;
}

.tableSection table.table {
	max-height: 600px;
	overflow-y: scroll;
}

.tableSection table.table th,
td {
	padding: 12px 20px !important;
}
.tableSection table.table th {
	color: #484848;
	font-size: 14px;
}

.tableSection table.table td p a {
	font-size: 14px;
	color: #1f69a3;
	text-decoration: none;
	font-weight: 500;
}
.tableSection table.table td p {
	margin-bottom: 0px;
	color: #585858;
}
.tableSection table.table td span {
	/* color: #858585; */
	font-size: 12px;
}
.table > :not(:first-child) {
	border-top: 2px solid #e9e9e9 !important;
}

.badge {
	border-radius: 0px;
	min-width: 95px;
	height: 27px;
	font-size: 14px;
	line-height: 16px;
	font-weight: normal;
}
button.tableBtn-orange {
	min-width: 88px;
	height: 34px;
	border: 1px solid #f57a11;
	background: transparent;
	color: #f57a11;
	border-radius: 18px;
	font-size: 12px;
	margin: 0px 10px;
}
button.tableBtn-red {
	min-width: 88px;
	height: 34px;
	border: 1px solid #f50505;
	background: transparent;
	color: #f50505;
	border-radius: 18px;
	font-size: 12px;
	margin: 0px 10px;
}
button.tableBtn-grey {
	min-width: 88px;
	height: 34px;
	border: 1px solid #8e8d8b;
	background: transparent;
	color: #8e8d8b;
	border-radius: 18px;
	font-size: 12px;
	margin: 0px 10px;
}

.actionArea {
	display: flex;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
}

.actionArea a {
	margin: 0 10px;
}
.iconBtn {
	width: 30px;
	height: 30px;
}
.shippingBtn {
	background: url(../images/shipping-icon.svg) no-repeat center center;
	background-size: 25px;
	width: 25px;
	height: 30px;
}

.cardIconSec {
	width: 45px;
	height: 45px;
}
.cardIconSec img {
	width: auto;
}

.cardDesc {
	display: flex;
	align-content: center;
	margin-left: 20px;
}

.cardDesc p {
	margin-bottom: 0px;
}
.cardDesc a {
	text-decoration: none;
	color: #585858;
}
.cardDesc a:hover {
	color: #f57a11;
}

section.cardBoxWrapper .cardBox {
	justify-content: center;
	align-items: center;
	flex-direction: inherit !important;
}

.formSearcharea .form-select,
.formSearcharea .form-control {
	height: 41px;
	border-radius: 0px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	outline: none;
	font-size: 14px;
	color: #8a8989;
	min-width: 230px;
	max-width: 100%;
}
.multi-select-box-width-fix {
	max-width: 240px;
}
.search-box-width-fix {
	min-width: 350px;
}

@media screen and (max-width: 480px) {
	.multi-select-box-width-fix {
		max-width: 100%;
		margin-right: 0px;
	}
	.search-box-width-fix {
		min-width: 100%;
	}

	.formSearcharea .form-select,
	.formSearcharea .form-control {
		min-width: 100%;
	}

	.formSearcharea .col-auto {
		flex: 0 0 100%;
		width: 100%;
		display: block !important;
	}
}

.filterBtn {
	width: 41px;
	height: 35px;
	border-radius: 0px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
}

.headRight {
	text-align: right;
}

.topSubHead {
	font-size: 16px;
}

.smallInput {
	width: 50px;
	border: none !important;
	border-radius: 0px !important;
	border-bottom: 1px solid #aeaeae !important;
	height: 30px;
}
.smallInputCart {
	width: 30%;
	max-width: 81px;
	/* font-size: 12px; */
	padding: -4px;
	/* border-radius: 4px !important;
  border: 1px solid #aeaeae !important;
  */
	height: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.bottomTicSection {
	padding-left: 20px;
	padding-right: 20px;
	font-size: 13px;
	color: #929292;
}

.tableFooter {
	width: auto;
	min-height: 58px;
	background-color: #1f69a3;
	color: #fff;
	padding: 20px;
	margin: 20px;
}

.tableFooter p {
	margin-bottom: 0px;
}

.tabfooterRight {
	text-align: right;
}

.footerBtnSec {
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
}

.btnBig {
	min-width: 171px;
	max-width: 171px;
	height: 50px;
	border-radius: 25px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	display: block;
	/* line-height: 50px; */
}

.btnSmall {
	min-width: 150px;
	height: 40px;
	border-radius: 25px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
}
.orangeBtn {
	background-color: #f57a11;
	border-color: #f57a11;
	color: #fff;
}

.blueBtn {
	background-color: #1e4696;
	border-color: #1e4696;
	color: #fff;
}

a.orangeBtn,
a.blueBtn {
	color: #fff;
}
a.btnBig {
	text-decoration: none;
}

.table.table-bordered {
	border-left-color: #fff;
	border-right-color: #fff;
}

.react-datepicker-wrapper input {
	/* padding : 0 0 0 48px; */
	background: url(../images/date-icon.svg) no-repeat center right 20px #fff;
	max-width: 180px !important;
}
.search-icon-right {
	/* padding : 0 0 0 48px; */
	background: url(../images/search-icon.svg) no-repeat center right 20px #fff;
	max-width: 180px !important;
}

.not-allowed {
	cursor: not-allowed;
}
.cursor-pointer {
	cursor: pointer;
}

.searchWrapper {
	position: relative;
}

button.searchBtn {
	position: absolute;
	top: 8px;
	right: 10px;
	background: transparent;
	border: none;
	outline: none;
}

button.suffixIcon {
	position: absolute;
	top: 8px;
	right: 10px;
	background: transparent;
	border: none;
	outline: none;
}
button.suffixIcon img {
	height: 18px;
}

.loginWrapper {
	background: url(../images/login_bg_without_logo.jpg) no-repeat center center fixed;
	/*  background: url(../images/login_bg.png) no-repeat center center fixed; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100vh;
	overflow: hidden;
}

@media (max-width: 768px) {
	.loginWrapper {
		background: url(../images/login_bg_without_logo.jpg) no-repeat center top 0% fixed #fff;
		/* 	background: url(../images/login_bg.png) no-repeat center top 20% fixed #fff;	 */
		background-size: 100%;
		/* height: 100vh; */
		overflow: hidden;
	}
}

.loginBox {
	width: 500px;
	min-height: 520px;
	/* background-color: #fff; */
	border-radius: 15px;
	flex-direction: column;
	padding: 20px;
	/* 	box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%); */
}

.btnDiv {
	display: flex;
	justify-content: center;
}
.loginBtn,
a.loginBtn {
	width: 100%;
	max-width: 300px;
	height: 50px;
	border-radius: 25px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	display: block;
	/* line-height: 50px; */
	margin-top: 15px;
	text-decoration: none;
}

a.loginBtn {
	line-height: 50px;
}

.loginBox .logoSec {
	margin-bottom: 30px;
}

.loginBox .form-control {
	height: 50px;
	border-radius: 25px;
	border: 1px solid #ccc;
	outline: none;
	font-size: 14px;
	color: #8a8989;
	min-width: 300px;
	max-width: 300px;
}

.form-floating > label {
	position: absolute;
	top: -5px;
	left: 10px;
}

.loginBox .link-text {
	font-size: 13px;
	line-height: 24px;
	text-decoration: none;
	color: #333;
}
.loginBox .link-text:hover {
	text-decoration: underline;
}

.login-info-text {
	font-size: 13px;
	line-height: 16px;
	margin-top: 15px;
	max-width: 80%;
}

.login-info-text span {
	color: #ff4444;
}

/* Absolute Center Spinner */
.loading {
	position: fixed;
	z-index: 999;
	height: 80px;
	width: 200px;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
}

.loading img {
	z-index: 9999;
	position: relative;
	display: block;
	margin: 0 auto;
	margin-bottom: 15px;
}

.spinner-grow {
	background-color: #ff4444;
}

/* Transparent Overlay */
.loading:before {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));

	background: -webkit-radial-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
}

.pageformSection {
	margin-top: 20px;
}
.pageformSection .form-control {
	height: 50px;
	border-radius: 25px;
	border: 1px solid #ccc;
	outline: none;
	font-size: 14px;
}

.pageformSection .form-select {
	height: 50px;
	border-radius: 25px;
	border: 1px solid #ccc;
	outline: none;
	font-size: 14px;
}

.pageformSection .card {
	padding: 48px 34px;
	border-radius: 0px;
}

.form-info-text {
	font-size: 13px;
	line-height: 16px;
	margin-top: 15px;
	max-width: 80%;
}

.detailsDataSec .card-header {
	display: flex;
	align-content: center;
	justify-content: space-between;
	font-weight: 600;
	font-size: 18px;
}

.btn-warning {
	color: #fff;
	background-color: #f57a11;
	border-color: #f57a11;
}
.btn-warning:hover {
	color: #fff;
	background-color: #f57a10;
	border-color: #f57a10;
}

span.error {
	display: block;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #dc3545;
}

/*scrollTable*/
.tableContainer {
	display: flex;
}
.tableScroll {
	width: 100%;
	overflow-x: scroll;
}
.fixed {
	border-spacing: 0;
}
.fixed thead tr {
	height: 30px;
}
.tableScrollable thead tr {
	height: 30px;
}

/* .tableScrollable thead{

  } */
.tableScrollable {
	/* width:100px; */
	border-spacing: 0;
}
/* .tableContainer td, .tableContainer th{
     max-width:150px !important; 
  } */
.tableContainer .table {
	width: auto;
	margin-bottom: 0px;
}
.w-cus {
	min-width: 450px !important;
	max-width: 100% !important;
}

.tableContainer table.table th {
	background-color: #1e4696;
	color: #fff;
}

.cartRightAlign button {
	float: right;
}
.bonusOffer {
	display: flex;
	justify-content: flex-start;
}
.bonusOffer img {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
.detailsLinkBtn {
	text-align: right;
	display: inline-block;
	/* float: right; */
	font-size: 14px;
	color: #333;
}
.detailsLinkBtn:hover {
	color: #1e4696;
}

.sortWrapper {
	min-width: 200px;
	padding: 10px;
}

.dropdown-menu.show {
	top: 10px !important;
}

.card-header p {
	margin-bottom: 0px;
}

.popupOuter {
	position: relative;
}
.showPopupBtn.btn.btn-primary {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: transparent;
	border: none;
	outline: none;
}
.showPopupBtn.btn.btn-primary:hover {
	background-color: transparent;
	border: none;
	outline: none;
}

.showPopupBtn.btn.btn-primary:focus {
	background-color: transparent;
	border: none;
	outline: none;
	box-shadow: none;
}

.popUpInnerSearchWrapper {
	margin: 20px 0px;
	max-height: 400px;
	overflow: hidden;
	overflow-y: scroll;
}

.popUpInnerSearchWrapper .form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5em;
	margin-bottom: 0.125rem;
	border-bottom: 1px solid #eee;
	padding: 10px 30px;
}

.searchModal .modal-dialog {
	max-width: 600px !important;
}

.searchModal .modal-title.h4,
.sortModal .modal-title.h4 {
	font-size: 18px;
}

.sortModal .modal-dialog {
	max-width: 250px !important;
}

.detailsDataSec {
	margin-top: 15px;
}

/* .css-1s2u09g-control{
	border-radius: 0px !important;
	border: none !important;
	font-size: 14px;
	color: #8A8989;
	min-height: 41px !important;
} */

/* .css-1pahdxg-control{
	border-radius: 0px !important;
	border: none !important;
	font-size: 14px;
	color: #8A8989;
} */

.detailsDataSec .card-header a {
	text-decoration: none;
	color: #1e4696;
}
/* .content-wrapper .card {
	border-radius: 0px;
} */

.browseFile {
	background: url(../images/browse-file.svg) no-repeat 0;
	background-size: 282px;
	width: 282px;
	height: 50px;
	text-indent: -999999px;
}

.cartRightAlign {
	text-align: right;
}
.backBtn {
	background: url(../images/back.png) no-repeat;
	background-size: 32px;
	width: 34px;
	height: 34px;
	border: none;
	outline: none;
	margin-right: 15px;
}

.contentHeaderSec h3 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

/* .navBottomBtn {
	text-align: center;
	position: fixed;
	bottom: 20px;
	left: 30px;
	background-color: #fff;
} */

.formSearcharea {
	z-index: 20 !important;
}

.pagination {
	justify-content: end;
	margin-top: 20px;
}
.btnAbs {
	position: relative;
}
.absClose {
	position: absolute;
	display: block;
	right: 13px;
	height: 39px;
	width: 40px;
	top: 1px;
}

textarea.form-control {
	min-height: 150px;
	padding: 20px 20px;
}

.callLabel.form-label,
.emailLabel.form-label {
	display: flex;
}

.callLabel.form-label img,
.emailLabel.form-label img {
	width: 30px;
	margin-right: 15px;
}

.callLabel.form-label a,
.emailLabel.form-label a {
	text-decoration: none;
	color: #585858;
}

.callLabel.form-label a:hover,
.emailLabel.form-label a:hover {
	color: #1e4696;
}

.fs-6 {
	font-size: 0.85rem !important;
}
.ws-6 {
	width: 1.5rem !important;
}
.App {
	font-family: sans-serif;
	text-align: center;
}
#box-in-pr {
	display: flex;
	text-transform: capitalize;
	display: 'flex';

	width: 95%;
}
#box-in-pr::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#box-in-pr {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
#box-in-pr div {
	background-color: rgb(231, 231, 237);
	min-width: max-content;
	padding: 2px 19px 2px 15px;
	border-radius: 2px;
	margin: 3px 4px 3px 4px;
	font-size: 11px;
}
/* #box-pr:focus {
	box-shadow: rgb(67, 149, 255) 0px 0px 0px 2px;
} */

#box-in-pr {
	overflow: auto;
	margin-right: 8px;
}

#box-pr {
	padding: 5px;
	cursor: pointer;
	display: flex;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	border-radius: 2px;
	min-height: 35px;
	background-color: white;
	min-width: 230px;
}

@media screen and (max-width: 480px) {
	#box-pr {
		min-width: 100%;
		width: 100%;
	}
}

#x {
	padding-top: 0px;
	padding-right: 4px;
	border: 0px;
}
#x button {
	border: 0px;
}
#x img {
	width: 18px;
}
#x1 {
	text-align: left;
}

div[disabled] {
	opacity: 1;
	cursor: pointer;
	pointer-events: none;
}

li[disabled] {
	opacity: 1;
	color: rgb(58, 57, 57);
	background: rgb(233, 231, 231);
	cursor: pointer;
	pointer-events: none;
}
#box-in-pr-pl {
	white-space: nowrap;

	padding-left: 6px;
	padding-top: 4px;
	font-size: 14px;
}

.html-decode {
	white-space: pre-wrap;
}
.loading_reddy {
	width: auto;
	height: auto;
	margin: 0;
	transform: translate(-50%, -50%);
}
.loading_reddy_outer {
	background-color: rgba(0, 0, 0, 0.7);
}

.tooltip {
	display: inline-block !important;
	z-index: 9;
}
.rupee-icon {
	font-family: none !important;
	font-weight: 'bold';
}
.form-check-input {
	cursor: pointer;
}
.mt--5 {
	margin-top: -2px;
}
.logo-size {
	width: 80px;
}

img.productSampleimg {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	margin-right: 15px;
}
.input-boder:focus {
	border: 2px solid #a3c3ef !important;
}

.card p {
	margin-bottom: 6px;
}

.RebuttonArea button.btn.btn-warning {
	font-size: 13px;
}

.bg-none {
	background-color: transparent !important;
	background: transparent !important;
}

span.progressDate {
	font-size: 10px;
	margin-left: 10px;
}

.boxcardHead {
	font-size: 14px;
}

.custom-hover-effect:hover {
	color: #1e4696;
}
.vr {
	margin: 0px 5px;
}
.pofile {
	cursor: pointer;
}
.pofile:hover {
	cursor: pointer;
	color: #1e4696 !important;
	font-weight: bold;
}

.possword-relative {
    position: relative;
}

.passord-icon-pozition {
    position: absolute;
    right: 10px;
    top: 6px;
}
.m-10{
	margin-right: 10px;
}
.bottomUploadInfo{
	width: 100% !important;
	float: right;
	text-align: right;
}
.upload-order-size.bottomUploadInfo {
    padding-bottom: 0px;
}
.bottomUploadInfo .customfile-cart {
    position: relative;
    right: 0px;
    padding: 10px;
}

.customfile-cart.text-end {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 10px;
	align-items: baseline;
}
span.uploadTxt {
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 0 8px;
    height: 30px;
    display: flex;
    align-items: center;
}